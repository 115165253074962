import { UserContext, Login } from '../../actions/action-types';
import {
  commonActions,
  commonEnums,
  cmxTypeguards
} from '@codametrix/ui-common';
import { buildRoleSet } from '../../reducers/users/roles';

import mixpanel from 'mixpanel-browser';
import { MiddlewareProcessor, middleWareWrapper } from './middleware-utils';

const { UserInterface, Bench, AttachDocs, MergeCases } = commonActions;
const { ProcessInstanceActions } = commonEnums;

let initialized = false;
let globalUserId: string = '';

const initialize = (apiKey: string) => {
  mixpanel.init(apiKey, {
    api_host: window.location.origin,
    debug: true
  });
  return true;
};

const setUserRoles = (context: CMx.Context) => {
  return buildRoleSet(context);
};

const setUserOrgs = (context: CMx.Context) => {
  return context.contexts.map((context: CMxAPI.OrganizationContext) =>
    context.organizationCode.toString()
  );
};

const identifyUser = (userId: string) => {
  globalUserId = userId;
  mixpanel.identify(userId);
};

const setUserProperties = (user: CMxAPI.User, context: CMx.Context) => {
  const activeRoles = setUserRoles(context);
  const activeOrg = context.activeContext?.organizationCode;
  const availableOrgs = setUserOrgs(context);

  mixpanel.people.set({
    'Recently Active Roles': activeRoles,
    'Recently Active Organization': activeOrg,
    'Available Organizations': availableOrgs
  });

  mixpanel.people.set_once({
    $name: user.firstName + ' ' + user.lastName,
    $email: user.username
  });
};

// minitab values don't seem to be exposed, but needed for determining correct 'view'
type MiniTabKey = 'Related' | 'Activity' | 'Charges' | 'Improvements';

const miniTabPrettyNames: Record<MiniTabKey, string> = {
  Related: 'Open Related Case Tab',
  Activity: 'Open Case Activity Tab',
  Charges: 'Open Case Charges Tab',
  Improvements: 'Open Improvements Tab'
};

/**
 * Middleware inspecting dispatched Redux actions - currently for DataDog views and MixPanel events
 *
 * MixPanel observes Redux actions and sets up a sense of sessions (via "events")
 *
 * DataDog has three entry points to its use of "views" (it is also doing deeper monitoring of events in the page,
 * enabling it to time things like query time and page responsiveness:)
 *  - monitor the URL (but we manually take this over so that we can replace partial URLs with 'friendlier' view names
 *  - observe redux actions
 *  - manually call window.DD_RUM.startView("View Name");, like in a click handler
 *
 * We currently only engage the first two but the other is available in a global, decoupled way.
 **/
const analyticsProcessor: MiddlewareProcessor = (api, next, action) => {
  if (!cmxTypeguards.isFSA(action)) {
    return next(action);
  }

  const {
    ui: { activeUser, config }
  }: CMx.ApplicationState = api.getState();

  /**
   * DataDog View interceptor. Certain Redux actions (often ones involving modals etc)
   * effectively change the view but only would be observable here (at least without
   * changing the event handler)
   **/

  switch (action.type) {
    case Bench.SET_ACTIVE_MINI_TAB:
      startDataDogView(
        miniTabPrettyNames[action.payload as MiniTabKey] || 'Open Case Tab'
      );
      break;
    case AttachDocs.SHOW_ATTACH_DOCS_MODAL:
      startDataDogView('Open Add Doc');
      break;
    case `${MergeCases.FETCH_MERGEABLE_CASES}_STARTED`:
      startDataDogView('Open Merge Window');
      break;
    case `${MergeCases.FETCH_MERGE_CASES}_STARTED`:
      startDataDogView('Submit Merge');
      break;
    case `${ProcessInstanceActions.INVOICE_SUBMITTED}_STARTED`:
      startDataDogView('Submit Case');
      break;
    case `${Bench.TOGGLE_CHARGEABLE_CASE}_STARTED`:
      startDataDogView('Exclude Case');
      break;
    case `${Bench.HOLD_CASE}_STARTED`:
      startDataDogView('Submit Hold');
      break;
    case Bench.SHOW_SPLIT_CASE_MODAL:
      startDataDogView('Open Split Window');
      break;
    case `${MergeCases.SPLIT_CASE}_STARTED`:
      startDataDogView('Submit Split');
      break;
    //(manager only)
    case `${Bench.CANCEL_INVOICES_CONFIRM}_STARTED`:
      startDataDogView('Void Case');
      break;
  }

  /**
   * MixPanel Tracking
   **/

  if (config?.mixpanelApiKey !== undefined && action.meta?.analytics === true) {
    if (!initialized) {
      initialized = initialize(config.mixpanelApiKey);
    }

    switch (action.type) {
      case Login.LOGOUT:
      case Login.LOGIN_STARTED:
        mixpanel.reset();
        globalUserId = '';
        break;
      case UserInterface.SET_ACTIVE_USER:
        if (globalUserId.length === 0 && activeUser.userId?.length > 0) {
          identifyUser(activeUser.userId);
        }
        break;
      case UserContext.CHOOSE_CONTEXT:
        if (globalUserId.length === 0) {
          identifyUser(activeUser.userId);
        }
        setUserProperties(activeUser, action.payload);
        break;
      default:
        break;
    }

    let eventProperties;
    const actionType = action.type.replace(/[-_]/g, ' ');
    mixpanel.track(actionType, eventProperties);
  }

  return next(action);
};
const analyticsMiddleware = middleWareWrapper(analyticsProcessor);

// DataDog can accept arbitrary fields
export type DataDogViewDescription = {
  name: string;
  'service-line'?: string;
};

export const startDataDogView = (view: string | DataDogViewDescription) => {
  // @ts-ignore
  window.DD_RUM && window.DD_RUM.startView(view);
};

const dataDogViewMatchers: {
  pattern: RegExp;
  getDescription: (matches: RegExpExecArray) => DataDogViewDescription;
}[] = [
  {
    pattern: /^\/cmx\/cases\/([a-zA-Z\s-]+)\/([a-zA-Z0-9-]+)$/,
    getDescription: matches => ({
      name: 'Open Case',
      serviceline: matches[1]
    })
  },
  {
    pattern: /^\/cmx\/cases\/([a-zA-Z\s-]+)\/$/,
    getDescription: matches => ({
      name: 'Open Cases',
      serviceline: matches[1]
    })
  },
  {
    pattern: /^\/cmx\/worklist\/.*?\/case\/.*?\/case-run\/.*?$/,
    getDescription: () => ({
      name: 'Open Coder Screen'
    })
  },
  {
    pattern: /^\/cmx\/worklist\/$/,
    getDescription: matches => ({
      name: 'Open Worklist'
    })
  },
  {
    pattern: /^\/cmx\/queue-settings\/$/,
    getDescription: matches => ({
      name: 'Open Queue settings'
    })
  },
  {
    pattern: /^\/cmx\/coding-quality\/$/,
    getDescription: matches => ({
      name: 'Open Coding Quality'
    })
  },
  {
    pattern: /^\/cmx\/admin\/orgs\//,
    getDescription: matches => ({
      name: 'Open Admin settings'
    })
  }
];

export const handleLocationChange = (location: any) => {
  const viewDescriptor = getDataDogViewDescription(location.pathname);
  startDataDogView(viewDescriptor);
};

const getDataDogViewDescription = (
  url: string
): DataDogViewDescription | string => {
  for (const { pattern, getDescription } of dataDogViewMatchers) {
    const matches = pattern.exec(url);
    if (matches) {
      return getDescription(matches);
    }
  }
  return url;
};

export default analyticsMiddleware;
